import styled, { css } from 'styled-components';

const BoldFont = 'Open-Sans-Bold';

const TopbarGrid = styled.div`
  width: 100%;
  background-color: ${({backgroundColor})=>backgroundColor};
  float: left;
  padding: 10px 0;
  color: #56d4dd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  margin-bottom: 5px;
  @media (max-width: 500px) {
    padding: 10px 10px;
  }
`;

const Logo = styled.div`
  padding-left: ${({padding}) => padding ? '0px' : '25px'};
  position: relative;
  cursor: pointer;
  height: ${({height}) => height ? '' : '100%'};
  display: inline-block;
  float: left;
  min-width: ${({padding}) => padding ? '200px' : 'none'};
  display: ${({padding}) => padding ? 'flex' : 'inline-block'};
  justify-content: ${({padding}) => padding ? 'center' : 'none'};
  align-items: ${({padding}) => padding ? 'center' : 'none'};
  
  > img {
    height: ${({height}) => height ? '' : '100%'};
    max-width: 180px;
  }

  @media (max-width: 950px) {
    padding-left: 25px;
    min-width: ${({padding}) => padding && 'unset'};
    display: ${({padding}) => padding && 'inline-block'};
    justify-content: ${({padding}) => padding && 'unset'};
    align-items: ${({padding}) => padding && 'unset'};
  }

  @media (max-width: 500px) {
    padding-left: 0px;
  }
  
  @media (max-width: 430px){
    width: ${({padding}) => padding ? '88px': '119px'};
    height: ${({padding}) => padding ? '60px' : '45px'};
    min-width: ${({padding}) => padding && 'unset'};
    object-fit: contain;
    > img {
        height: 100%;  
        width: 100%;
    }
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: ${BoldFont};
  padding: 0 20px;
  cursor: pointer;
  position: relative;
  //margin-right: 20px;
  &:hover {
    > svg g path {
        fill: ${({color}) => color || "#3d4d67"};
    }
  }

  & > img {
    width: 19px;
    height: 19px;
  }
  
  @media (max-width: 500px) {
    padding: 10px 6px;
  }
  &:hover {
    > svg {
      fill: ${({color}) => color || "#3d4d67"};    
    }
  }
  > svg {
    margin-top: 3px;
    fill: rgb(156, 156, 156);
    width: 22px;
    height: 22px;
    position: relative;
    pointer-events: none;
  }
  > svg g path {
    fill: ${({active}) => active ? '#3d4d67' : '#9c9c9c'};
  }
  span{
   position: absolute;
    height: 16px;
    width: 20px;
    background: #FD7175;
    border-radius: 50%;
    left: 31px;
    top: -3px;

    font-family: Rubik;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    color: white;
    text-align:center;
  }
  @media (max-width: 500px){
    span{
      top: 16px;
      left: 19px;
    }
  }
  ${({ active }) => active && css`
    > svg {
      fill: ${({color}) => color || "#3d4d67"};
    }
  `}
`;

const Settings = styled(IconContainer)`
  position: relative;
  padding-right: 0;
  height: 100%;
  > svg {
    margin-bottom: -10px;
    margin-left: 9px;
    @media (max-width: 500px) {
      display: none;
    }
  }
  @media (max-width: 500px) {
      display: none;
  }

  svg:hover {
    fill: ${({color}) => color || "#3d4d67"};
  }
  
  > ul {
    width: 170px;
    list-style: none;
    display: none;
    position: absolute;
    top: 35px;
    right: 10px;
    background-color: white;
    font-family: ${BoldFont};
    padding: 0;
    z-index: 1;
    box-shadow: 1px 1.732px 46px rgba(0,0,0,0.18);
    
    @media (max-width: 500px) {
      display: none;
    }

    > li {
      text-align: center;
      padding: 5px 0;
      > a {
        width: 100%;
        display: block;
        padding: 5px 30px;
        color: #67676A;
        cursor: pointer;
        &:hover {
          text-decoration: none;
          color: white;
        }
      }
    }
  }
  &:hover > ul {
    display: block;
  }
  
  ul > li:hover {
    color: white;
    background-color: ${({color}) => color || "#3d4d67"};
  }
  
  > img {
    width: 20px;
    height: 20px
  }
`;

const IconsWrapper = styled.div`
  float: right;
  display: flex;
  margin-right: 30px;
  height: 100%;
  align-items: center;  
  @media (max-width: 500px) {
    margin-right: 0;
  }
`;

const ProfileContainer = styled.div`
  height: 44px;
  width: 180px;
  float: left;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    width: 100%;
  }

  .middle {
    display: table-cell;
    vertical-align: middle;
    display: inline;
    > img {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      float: left;
      margin-right: 10px;
      object-fit: cover;
    }
    > ul {
      width: 170px;
      list-style: none;
      display: none;
      position: absolute;
      top: 60px;
      right: 10px;
      background-color: white;
      font-family: ${BoldFont};
      padding: 0;
      z-index: 1;
      box-shadow: 1px 1.732px 46px rgba(0,0,0,0.18);
  
      > li {
        text-align: center;
        padding: 5px 0;
        > a {
          width: 100%;
          display: block;
          padding: 5px 30px;
          color: #67676A;
          cursor: pointer;
          &:hover {
            text-decoration: none;
            color: white;
          }
        }
      }
    }
    @media (max-width: 500px) {
      &:hover > ul {
        display: block;
        width: 120px;
      }
    }
    
    ul > li:hover {
      color: white;
      background-color: ${({color}) => color || "#3d4d67"};
    }
  }
`;

const UserName = styled.strong`
  font-family: Avenir;
  font-size: 12.8px;
  line-height: 19px;
  color: #67676A;
  vertical-align: middle;
  display: table-cell;
  letter-spacing: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
  word-spacing: normal;
  cursor: pointer;

  @media (max-width: 600px) {
    display: none;
  }
`;
export { UserName, ProfileContainer, TopbarGrid, Logo, IconContainer, Settings, IconsWrapper};
