/* eslint-disable indent */
/* eslint-disable no-console,no-undef, camelcase */
/* eslint-disable react/no-unknown-property */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {TopbarGrid, Settings, IconsWrapper,ProfileContainer, UserName} from './style';
import {Languagecontainer} from '../TopBarV3/style'
import {/*ArrowImg,*/ Logo, /*CollapsArrow ,*/IconContainer} from '../TopBarV3/style';
import {ImageUrl} from '../../utils/constants';
import Notification from '../Notification';
import {COMPANY_NAME} from '../../utils/config';
import { putUpdateLanguage } from '../../redux/actions';
import { connect } from 'react-redux';
import i18n from '../../Lang/i18n'; 
import LazyImage from '../common/LazyImage/LazyImage';
//import Loading from '../Loading';

const dashboardIcon = (
  <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      d="M13.200,0.557 C6.300,-1.797 -0.000,3.659 -0.000,10.720 C-0.000,11.683 0.100,13.502 0.400,14.572
      C0.400,14.786 0.600,15.000 0.800,15.000 L19.100,15.000 C19.300,15.000 19.500,14.786 19.600,14.572 C19.800,13.502
      20.000,11.683 20.000,10.720 C20.000,6.120 17.300,1.948 13.200,0.557 ZM10.000,2.162 C10.600,2.162 11.000,2.590 11.000,3.231
      C11.000,3.873 10.600,4.301 10.000,4.301 C9.400,4.301 9.000,3.873 9.000,3.231 C9.000,2.590 9.400,2.162 10.000,2.162 ZM3.000,11.790
      C2.400,11.790 2.000,11.362 2.000,10.720 C2.000,10.079 2.400,9.651 3.000,9.651 C3.600,9.651 4.000,10.079 4.000,10.720 C4.000,11.362 3.600,11.790 3.000,11.790 ZM5.800,6.227
      C5.400,6.655 4.800,6.655 4.400,6.227 C4.000,5.799 4.000,5.050 4.300,4.622 C4.700,4.194 5.300,4.194 5.700,4.622 C6.100,5.050 6.100,5.799 5.800,6.227 ZM10.000,12.860 C8.900,12.860 7.900,11.790 8.200,10.720
      C8.500,9.651 10.000,6.441 10.000,6.441 C10.000,6.441 11.500,9.651 11.800,10.720 C12.100,11.790 11.100,12.860 10.000,12.860 ZM15.700,6.227 C15.300,6.655 14.700,6.655 14.300,6.227 C13.900,5.799 13.900,5.157 14.300,4.729 C14.700,4.301 15.300,4.301 15.700,4.729
      C16.000,5.050 16.000,5.799 15.700,6.227 ZM17.000,11.790 C16.400,11.790 16.000,11.362 16.000,10.720 C16.000,10.079 16.400,9.651 17.000,9.651 C17.600,9.651 18.000,10.079
      18.000,10.720 C18.000,11.362 17.600,11.790 17.000,11.790 Z"
    />
  </svg>);

// const searchIcon = (
//   <svg>
//     <path
//       fillRule="evenodd"
//       d="M15.609,13.717 L12.133,10.242 C12.114,10.223 12.092,10.210 12.073,10.193 C12.757,9.155 13.156,7.913 13.156,6.578 C13.156,2.945
//       10.211,-0.000 6.578,-0.000 C2.945,-0.000 -0.000,2.945 -0.000,6.578 C-0.000,10.211 2.945,13.156 6.578,13.156 C7.914,13.156 9.155,12.757
//       10.193,12.073 C10.210,12.092 10.223,12.114 10.242,12.132 L13.718,15.608 C14.240,16.130 15.086,16.130 15.609,15.608 C16.131,15.086 16.131,14.240
//       15.609,13.717 ZM6.578,10.876 C4.204,10.876 2.280,8.951 2.280,6.578 C2.280,4.204 4.205,2.280 6.578,2.280 C8.952,2.280 10.876,4.204 10.876,6.578 C10.876,8.951
//       8.952,10.876 6.578,10.876 Z"
//     />
//   </svg>);
// //
// const settingIcon = (
//   <svg>
//     <path
//       fillRule="evenodd"
//       d="M18.95 9C18.92 8.74 18.62 8.55 18.37 8.55C17.54 8.55 16.81 8.07 16.5 7.32C16.19 6.55 16.39 5.66 17.01 5.09C17.2 4.91 17.22 4.62
//       17.06 4.41C16.64 3.88 16.16 3.39 15.63 2.97C15.43 2.81 15.13 2.83 14.95 3.03C14.41 3.62 13.45 3.84 12.7 3.53C11.93 3.21 11.44 2.42
//       11.49 1.58C11.5 1.31 11.31 1.08 11.04 1.05C10.37 0.97 9.69 0.97 9.02 1.05C8.76 1.07 8.56 1.3 8.57 1.56C8.6 2.4 8.11 3.17 7.34 3.48C6.6
//       3.78 5.65 3.56 5.11 2.97C4.93 2.78 4.64 2.76 4.43 2.92C3.89 3.34 3.4 3.83 2.97 4.36C2.81 4.56 2.83 4.86 3.03 5.04C3.65 5.61 3.86 6.51
//       3.53 7.29C3.22 8.03 2.45 8.51 1.57 8.51C1.29 8.5 1.08 8.69 1.05 8.95C0.97 9.62 0.97 10.31 1.05 11C1.08 11.25 1.38 11.44 1.64 11.44C2.42
//       11.42 3.17 11.9 3.49 12.67C3.81 13.44 3.6 14.33 2.99 14.9C2.79 15.08 2.77 15.37 2.93 15.58C3.35 16.11 3.83 16.6 4.36 17.02C4.56 17.18 4.86
//       17.16 5.04 16.96C5.58 16.37 6.55 16.15 7.29 16.46C8.07 16.78 8.55 17.57 8.51 18.41C8.49 18.68 8.69 18.91 8.95 18.94C9.29 18.98 9.64 19 9.99
//       19C10.32 19 10.64 18.98 10.97 18.95C11.24 18.92 11.43 18.69 11.42 18.43C11.39 17.6 11.89 16.82 12.65 16.51C13.39 16.21 14.35 16.43 14.88
//       17.02C15.06 17.21 15.36 17.23 15.56 17.07C16.1 16.65 16.59 16.17 17.02 15.63C17.19 15.43 17.16 15.13 16.97 14.95C16.34 14.38 16.14 13.48
//       16.46 12.7C16.77 11.97 17.51 11.48 18.3 11.48L18.42 11.48C18.67 11.51 18.91 11.31 18.94 11.04C19.02 10.37 19.02 9.68 18.95 9ZM10.01
//       13.02C8.35 13.02 7.01 11.67 7.01 10.01C7.01 8.36 8.35 7.01 10.01 7.01C11.67 7.01 13.01 8.36 13.01 10.01C13.01 11.67 11.67 13.02 10.01 13.02Z"
//     />
//   </svg>);

const profileDropdown = (
  <svg>
    <path d="M9.99991 1.19139L8.76109 0L4.99996 3.61714L1.23882 0L0 1.19139L4.99996 5.99992L9.99991 1.19139Z" />
  </svg>);

const checkBase64 = (image) => {
  let arr = image.split('/');
  if(arr[0] == 'profile-Image') {
    return `${ImageUrl}/${image}`
  }
  else {
    return image;
  }
};
class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideSideBar: false,
      userData: [],
      lang: (typeof localStorage !== 'undefined' && localStorage?.getItem('lang')) || 'en' || 'es'
    }
  }

  collapsSideBar = () => {
    const {hideContentOfSideBar, handleResponsiveCheck, handleAdminResponsiveCheck} = this.props;
    this.setState({hideSideBar: !this.state.hideSideBar});
    hideContentOfSideBar();
    handleResponsiveCheck();
    handleAdminResponsiveCheck();
  }

    takeLanguageData = (lang) => {
      const { putUpdateLanguage } = this.props;
      let obj = {
        user_language: lang === 'en' ? "english" : lang === 'es' ? 'Spanish' : 'French'
      };
      i18n.changeLanguage(lang);
      localStorage.setItem("lang", lang);
      this.setState({lang: lang});
      putUpdateLanguage(obj);
      window.location.reload();
    };

    componentDidMount() 
    {
      i18n.changeLanguage(this.state.lang);
    }
    
  render() {
    const {history, logout, companyLogo, userDetails, profileImage, readStatusCount,adminPortalAccess,colorData} = this.props;
    let navIndex = colorData && colorData.color_branding && colorData.color_branding.length > 0 ? colorData.color_branding.findIndex((data) => data.custom_name === 'nav_bar_color') : -1;
    let getColor = navIndex !== -1 ? colorData?.color_branding[navIndex]?.color_code : "#fff";
    let sideBarIndex = colorData && colorData.color_branding && colorData.color_branding.length > 0 ? colorData.color_branding.findIndex((data) => data.custom_name === 'side_menu_color') : -1;
    let getSideBarColor = sideBarIndex !== -1 && colorData?.color_branding[sideBarIndex]?.color_code;
    let {lang} = this.state;
    return <TopbarGrid backgroundColor={getColor}>
      <Logo padding={companyLogo && companyLogo.includes('Lonestar') || companyLogo && companyLogo.includes('RFA_logo') ? 1 : 0}>
        {companyLogo
          ?
          <img src={`${ImageUrl}/${companyLogo}`} alt="logo" onClick={() => history.push('/social')}/>
          :
          COMPANY_NAME === 'Woliba' ?
            <LazyImage src={ImageUrl + "/images/Logo/Woliba.svg"} alt="logo"
              onClick={() => history.push('/social')}/>
            :
            <img src="/public/images/Logo/training-amigo-horizontal-logo.png" alt="logo"
              onClick={() => history.push('/social')}/>
        }
      </Logo>
      <IconsWrapper>
        {
          adminPortalAccess && <div><IconContainer onClick={() => history.push('/company/dashboard')}
            active={history.location.pathname.includes('company')}
            color={getSideBarColor}>{dashboardIcon}          
          </IconContainer>
          </div>
        }
        {/* {
          searchBuddyCheck ?
            <div>
              <IconContainer onClick={() => history.push('/buddy-search')}
                active={history.location.pathname.includes('buddy-search')}
                color={getSideBarColor}>{searchIcon}</IconContainer>
            </div> : null
        } */}
        {<Notification readStatusCount={readStatusCount} history={history} color={getSideBarColor}/>}
        { this.props.userCompany.id === 1  ||  this.props.userCompany.id === 18 || this.props.userCompany.id === 17 ? 
        <div>
          <Languagecontainer color={getSideBarColor}>
            <IconContainer>
            <img src={`/images/NewHeader/${lang == 'en' ? "English.png" : lang == 'es' ? "Spanish.png" :"French.png"}`}/>
            </IconContainer>
            <ul>
              <li><a onClick={() => this.takeLanguageData("en")}><LazyImage src={ImageUrl + "/images/NewHeader/English.png"} />English</a></li>
              <li><a  onClick={() => this.takeLanguageData("es")}><LazyImage src={ImageUrl + "/images/NewHeader/Spanish.png"} />Spanish</a></li>
              <li><a onClick={() => this.takeLanguageData("fr")}><LazyImage src={ImageUrl + "/images/NewHeader/French.png"} />French</a></li>
              {/* <li><a><img src="/public/images/NewHeader/German.png" />German</a></li>
              <li><a><img src="/public/images/NewHeader/Japanese.png" />Japanese</a></li> */}
            </ul>
          </Languagecontainer>
        </div>
        : null
        }
        
        {/*<div>*/}
        {/*  <IconContainer onClick={() => history.push('/settings')}*/}
        {/*    active={history.location.pathname.includes('settings')} color={getSideBarColor}>{settingIcon}</IconContainer>*/}
        {/*</div>*/}
        <ProfileContainer color={getSideBarColor}>
          <div className="middle">
            <img
              alt={userDetails.fname}
              src={checkBase64(profileImage || userDetails.profile_image)}
              unloader={
                <img
                  src='/public/images/neutral_avatar.svg'
                  alt='avatar'
                />
              }
            />
            {/*<Image image={checkBase64(profileImage || userDetails.profile_image)} alt={userDetails.fname} />*/}
            <ul>
              <li><a onClick={() => history.push('/profile')}>Profile</a></li>
              <li><a>English</a></li>
              <li><a href={"https://woliba-kb.groovehq.com/help"} target="_blank"
                rel="noopener noreferrer">Help</a></li>
              <li><a onClick={() => logout()}>Log Out</a></li>
            </ul>
          </div>
          <Settings color={getSideBarColor}>
            <UserName onClick={() => history.push('/profile')}>
              {`${userDetails.fname} ${userDetails.lname}`}
            </UserName>
            {profileDropdown}
            <ul>
              <li><a onClick={() => history.push('/profile')}>Profile</a></li>
              <li><a>English</a></li>
              <li><a href={"https://woliba-kb.groovehq.com/help"} target="_blank"
                rel="noopener noreferrer">Help</a></li>
              <li><a onClick={() => logout()}>Log Out</a></li>
            </ul>
          </Settings>
        </ProfileContainer>
      </IconsWrapper>
    </TopbarGrid>
  }
}
Topbar.propTypes = {
  history: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  companyLogo: PropTypes.string,
  userDetails: PropTypes.object.isRequired,
  profileImage: PropTypes.string,
  readStatusCount: PropTypes.object,
  getReadStatusCountForNotification: PropTypes.func,
  adminPortalAccess: PropTypes.bool,
  colorData: PropTypes.object,
  hideContentOfSideBar: PropTypes.func,
  handleResponsiveCheck: PropTypes.func.isRequired,
  handleAdminResponsiveCheck: PropTypes.func.isRequired,
  searchBuddyCheck: PropTypes.number,
  putUpdateLanguage: PropTypes.func,
  userCompany:PropTypes.object
};
const mapStateToProps = (state) => ({
  userCompany: state.profileData.userCompany
})

const mapDispatchToProps = (dispatch) => ({
  putUpdateLanguage: (obj) => dispatch(putUpdateLanguage(obj)),
});
export default  connect (mapStateToProps, mapDispatchToProps)(Topbar);
